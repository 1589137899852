import * as React from "react";
import { useConfig } from "@fitplan/context/lib-es/config";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Input } from "@fitplan/lib/components/Input";
import { WebImage } from "@fitplan/lib/components/WebImage";
import { BrandButton } from "@fitplan/lib/components/Button/BrandButton";
import { IGiftCard } from "@fitplan/lib/types/stripe";
import { FitplanResult } from "@fitplan/lib/types/api";
import axios from "axios";
import { sentryReportException } from "@fitplan/lib/utils";

interface Props {
    onVerified: (giftcard: IGiftCard) => void;
}

export const VerifyGiftCard: React.FunctionComponent<Props> = ({ onVerified, ...props }) => {
    const { imageBaseUrl, apiBaseUrl } = useConfig();
    const [code, setCode] = React.useState("");
    const [error, setError] = React.useState("");
    const [pending, setPending] = React.useState(false);
    const { t } = useTranslation();
    return (
        <div>
            <form
                onSubmit={async (event) => {
                    event.preventDefault();
                    setPending(true);
                    try {
                        const result = await axios.get<FitplanResult<IGiftCard>>(`${apiBaseUrl}/v1/gift-card/validate/${code}`);
                        setPending(false);
                        if (result?.data?.error) {
                            setError("Could not validate gift card");
                        } else if (result?.data?.result?.canBeRedeemed) {
                            onVerified(result.data.result);
                        } else if (result?.data?.result?.canBeRedeemed === false) {
                            setError("This gift card code either does not exist or has already been used");
                        }
                    } catch (err) {
                        sentryReportException(err);
                        setError("An unknown error occurred. Please try again later.");
                    }
                }}
            >
                <input
                    type="text"
                    value={code}
                    onChange={(event) => setCode(event.target.value)}
                    placeholder={t("giftCode")}
                />
                {error && <div>{error}</div>}
                <BrandButton
                    type="submit"
                    disabled={pending || code.length < 5}
                >{t("redeemGiftCard")}</BrandButton>
            </form>
        </div>
    );
};