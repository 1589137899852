import * as React from "react";
import { gtagPageView } from "../../analytics";
import { Link } from "../Link";
import { StartFistIcon } from "./StartFistIcon";
import { BrandLink } from "../Button/BrandButton";
import { useConfig } from "@fitplan/context/lib-es/config";
import useMount from "react-use/lib/useMount";
import { useTatari } from "../../analytics/useTatari";
import { useTranslation } from "gatsby-plugin-react-i18next";
import "./StartPage.scss"

export interface DownloadStrings {
  success: string;
  button: string;
  exit: string;
}

export const StartPage: React.FunctionComponent<{}> = (props) => {
  const { isProduction } = useConfig();
  const { t } = useTranslation();
  const { tatariTrack } = useTatari();
  useMount(() => {
    gtagPageView("/account/subscribe/complete", undefined, isProduction);
    tatariTrack("pageview", { path: "/account/subscribe/complete" });
  });

  return (
    <div className="startPageContainer">
      <StartFistIcon />
      <h1 className="startPageHeading1">
        {t("accountActivated")}
      </h1>

      <BrandLink href="https://fitplan.app.link/downloadapp">
        {t("downloadFitplan")}
      </BrandLink>
      <Link className="startPageLink"
        to="/account"
      >
        {t("alreadyHaveTheApp")}
      </Link>
    </div>
  );
};
