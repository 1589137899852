import * as React from "react";
import styled from "@emotion/styled";
import {
    Container,
    Error,
    Header,
    Section,
} from "@fitplan/lib/components/NewSubscriptionModal/elements";
import { SignUpRow } from "@fitplan/lib/components/UserAuth/layout";
import { UserView } from "@fitplan/lib/components/NewSubscriptionModal/UserView";
import { StartPage } from "@fitplan/lib/components/NewSubscriptionModal/StartPage";
import { PlanView } from "@fitplan/lib/components/NewSubscriptionModal/PlanView";
import { useAuthContext } from "@fitplan/context/lib-es/auth";
import { SignUpForm, SignUpFormStrings } from "@fitplan/lib/components/UserAuth/SignUpForm";
import { IGiftCard } from "@fitplan/lib/types/stripe";
import { BrandButton } from "@fitplan/lib/components/Button/BrandButton";
import axios from "axios";
import { useConfig } from "@fitplan/context/lib-es/config";
import { useStrings } from "@fitplan/context/lib-es/strings";
import { LoginFormStrings, LoginForm } from "@fitplan/lib/components/UserAuth/LoginForm";
import { Link } from "@fitplan/lib/components/Link";

interface Props {
    giftcard: IGiftCard;
}

export const RedeemGiftCard: React.FunctionComponent<Props> = ({
    giftcard,
}) => {
    const [complete, setComplete] = React.useState(false);
    const [pending, setPending] = React.useState(false);
    const [isLogin, setIsLogin] = React.useState(false);
    const [error, setError] = React.useState("");
    const { user, access_token } = useAuthContext();
    const { apiBaseUrl } = useConfig();
    const loginStrings = useStrings<LoginFormStrings>("loginPage");
    const signupStrings = useStrings<SignUpFormStrings>("signUpPage");
    const ref = React.useRef<HTMLButtonElement>();
    return complete ? (
        <CompleteScreen active standalone>
            <StartPage />
        </CompleteScreen>
    ) : (
        <Container>
            <Container>
                <Section active={!user}>
                    {user && (
                        <UserView
                            name={`${user.firstName} ${user.lastName}`}
                            email={user.username}
                            avatarUrl={user.profileImage}
                        />
                    )}
                    {!user && (isLogin ? (
                        <>
                            <Header>Create Your Account or Login</Header>
                            <LoginForm onComplete={() => { }} />
                            <SignUpRow>
                                {loginStrings.footerText}
                                <Link to="/signup" onClick={(event) => {
                                    event.preventDefault();
                                    setIsLogin(false);
                                }}>{loginStrings.signUpLinkText}</Link>
                            </SignUpRow>
                        </>
                    ) : (
                        <>
                            <Header>Create Your Account or Login</Header>
                            <SignUpForm onComplete={() => { }} />
                            <SignUpRow>
                                {signupStrings.footerText}
                                <Link to="/login" onClick={(event) => {
                                    event.preventDefault();
                                    setIsLogin(true);
                                }}>{signupStrings.loginLinkText}</Link>
                            </SignUpRow>
                        </>
                    )
                    )}
                </Section>
                <Section>
                    <PlanView
                        title={giftcard.description}
                        subtitle="Gift Card"
                    />
                </Section>
                <Section active={!!user}>
                    <Button
                        ref={ref}
                        disabled={!user || pending}
                        onClick={async () => {
                            if (pending) {
                                return;
                            }
                            setPending(true);
                            if (ref?.current) {
                                ref.current.disabled = true;
                            }
                            try {
                                const result = await axios({
                                    url: `${apiBaseUrl}/v1/gift-card/redeem/${giftcard.code}`,
                                    headers: {
                                        Authorization: `Bearer ${access_token}`,
                                    },
                                });
                                if (result?.data?.result) {
                                    setComplete(true);
                                } else if (result?.data?.error) {
                                    setError(result.data.error);
                                } else {
                                    setError(
                                        "An unknown error occurred. Please try again later."
                                    );
                                }
                            } catch (err) {
                                setError(
                                    "An unknown error occurred. Please try again later."
                                );
                            }
                            setPending(false);
                        }}
                    >
                        Redeem Gift Card
                    </Button>
                    {error && <Error>{error}</Error>}
                </Section>
            </Container>
        </Container>
    );
};

const Button = styled(BrandButton)`
    display: block;
    height: 60px;
    border-radius: 30px;
    text-transform: uppercase;
    padding: 16px 24px;
    margin: 16px auto;

`;

const CompleteScreen = styled(Section)`
    margin-top: 80px;
`;