import React, { useState } from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Layout from "../../../components/layout/layout";
// import { IGiftCard } from "@fitplan/lib/types/stripe";
import { VerifyGiftCard } from "../../../components/RedeemGiftCard/VerifyGiftCard";
import { RedeemGiftCard } from "../../../components/RedeemGiftCard";
import { AuthFormStrings } from "../../../components/strings/AuthStrings";
import Modal from "../../../components/modal/Modal";
import { StaticImage } from "gatsby-plugin-image";
import "../giftcard.scss";

export const query = graphql`
  query ($languageISO: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    header: contentfulModules(
      type: { eq: "Header" }
      node_locale: { eq: $languageISO }
    ) {
      id
      items {
        ... on ContentfulItems {
          id
          link
          linkText
        }
      }
    }
    ribbon: contentfulModules(
      type: { eq: "Ribbon" }
      node_locale: { eq: $languageISO }
    ) {
      id
      heading
    }
    footer: allContentfulModules(
      filter: { type: { eq: "Footer" }, node_locale: { eq: $languageISO } }
    ) {
      nodes {
        id
        heading
        items {
          ... on ContentfulItems {
            id
            link
            linkText
          }
        }
      }
    }
  }
`;

const GiftCardRedeem = ({ data: { ribbon, header, footer } }) => {
  const [giftCard, setGiftCard] = useState(null);
  const { t } = useTranslation();
  return (
    <Layout header={header} ribbon={ribbon} footer={footer}>
      <AuthFormStrings>
        <section className="giftcard-hero">
          <StaticImage
            src="../../../images/hero_2.jpg"
            alt="gift card"
            placeholder="blurred"
            layout="fullWidth"
          />
          <div className="overlayer"></div>
          <div className="over-layer-text">
            <div className="container">
              <h1>
                {t("theFitplan")}<span>{t("giftCard")}</span>
              </h1>
            </div>
          </div>
        </section>
        <section className="gift-redeem-layout">
          <div className="container">
            <div className="giftcard-redeem-box-layout">
              <div className="gift-redeem-bow-card">
                <StaticImage
                  src="../../../images/bow-card.png"
                  alt="gift card"
                  placeholder="blurred"
                  layout="fullWidth"
                />
              </div>
              {!giftCard && (
                <VerifyGiftCard
                  onVerified={giftCard => setGiftCard(giftCard)}
                />
              )}
              {giftCard && (
                <Modal open={true}>
                  <RedeemGiftCard giftcard={giftCard} />
                </Modal>
              )}
              {/* <input type="text" placeholder={t("giftCode")} />
            <button type="button">{t("redeemGiftCard")}</button> */}
            </div>
          </div>
        </section>
      </AuthFormStrings>
    </Layout>
  );
};

export default GiftCardRedeem;
