import * as React from "react";

export const StartFistIcon: React.FunctionComponent<{}> = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="163"
    height="165"
    viewBox="0 0 163 165"
  >
    <defs>
      <linearGradient id="c" x1="0%" y1="100%" y2="0%">
        <stop offset="0%" stopColor="#12B587" />
        <stop offset="100%" stopColor="#29DB57" />
      </linearGradient>
      <circle id="b" cx="70.952" cy="81.05" r="70.952" />
      <filter
        id="a"
        width="132.4%"
        height="132.4%"
        x="-16.2%"
        y="-16.2%"
        filterUnits="objectBoundingBox"
      >
        <feMorphology
          in="SourceAlpha"
          operator="dilate"
          radius=".5"
          result="shadowSpreadOuter1"
        />
        <feOffset in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feMorphology in="SourceAlpha" radius="1" result="shadowInner" />
        <feOffset in="shadowInner" result="shadowInner" />
        <feComposite
          in="shadowOffsetOuter1"
          in2="shadowInner"
          operator="out"
          result="shadowOffsetOuter1"
        />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="7.5"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0.866666667 0 0 0 0 0.403921569 0 0 0 0 0.619607843 0 0 0 1 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g strokeLinecap="round">
        <g strokeDasharray="1,10" transform="translate(10.4 1.535)">
          <use fill="#000" filter="url(#a)" />
          <use stroke="url(#c)" />
        </g>
        <g stroke="#0076F9" strokeWidth="3">
          <path d="M83.208 1.92v17.764M95.149 8.2l-1.054 7.282M139.269 32.523l-5.307 3.998M118.26 149.959l-5.958-9.256M21.24 132.075l6.94-6.173" />
        </g>
      </g>
      <path
        fill="#12B587"
        fillRule="nonzero"
        d="M84.244 45.627c-3.502 0-6.667 1.639-8.586 4.361-1.333-.517-3.483-1.17-5.732-1.17-4.131 0-7.427 1.632-10.306 5.12a16.149 16.149 0 0 0-5.29.175C49.184 55.141 46 60.848 46 65.546v21.552c0 5.994 2.978 9.57 7.975 9.57 1.589 0 3.278-.168 4.686-.853l.1-27.864 3.19.013-.1 28.081c1.7 1.384 3.962 2.218 6.48 2.218 2.567 0 4.747-.654 6.38-1.794V64.768h3.19V94.63c.586.866 2.15 2.037 6.38 2.037 3.402 0 6.38-2.978 6.38-6.38v-25.52c0-3.745 1.85-5.701 2.063-5.913l2.255 2.255c-.031.031-1.128 1.234-1.128 3.658v25.52c0 3.402 2.979 6.38 6.38 6.38 3.116 0 6.5-.797 8-6.673l3.166-26.897c-.025-8.96-4.318-11.072-6.885-12.33l-.374-.188c-2.966-1.483-7.763-.735-9.788-.317-2.511-2.43-5.44-4.636-10.106-4.636zM113.64 71.31l-2.293 19.365c-1.545 6.18-5.178 9.184-11.115 9.184-3.284 0-6.244-1.758-7.976-4.362-1.732 2.604-4.691 4.362-7.975 4.362a19.48 19.48 0 0 1-2.773-.187 24.953 24.953 0 0 0-1.34 2.679c-1.24 2.916-1.351 5.184-.323 6.729.853 1.29 2.343 1.944 4.436 1.944 2.399 0 5.11-.605 7.975-1.24 4.979-1.11 10.617-2.361 15.359-.467l.286.112h.306c3.202 0 5.682-1.228 7.37-3.651 5.103-7.334 1.01-24.643-1.937-34.468z"
      />
    </g>
  </svg>
);
